<template>
  <div>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item prop="phone_number">
        <a-input v-model="form.phone_number" addon-before="+86" placeholder="手机号码" size="large">
          <a-icon slot="prefix" type="mobile" style="color: rgba(0,0,0,.25);"></a-icon>
        </a-input>
      </a-form-model-item>
      <template v-if="loginType === 'phone'">
        <a-form-model-item
          prop="verification_code"
          :rules="[
          { message: '请输入验证码', required: true },
          { message: '请输入 6 位验证码', pattern: /^\d{6}$/, trigger: 'blur' }
        ]"
        >
          <a-row :gutter="8">
            <a-col :span="15">
              <a-input v-model="form.verification_code" placeholder="验证码" size="large">
                <a-icon slot="prefix" type="mail" style="color: rgba(0,0,0,.25);"></a-icon>
              </a-input>
            </a-col>
            <a-col :span="9">
              <a-button
                :disabled="disabled"
                size="large"
                style="width: 100%; font-size: 14px;"
                @click="handleSend"
              >
                {{ disabled ? `${time} 秒后重新获取` : '获取验证码' }}
              </a-button>
            </a-col>
          </a-row>
        </a-form-model-item>
      </template>
      <template v-else>
        <a-form-model-item prop="password" :rules="[{ message: '请输入密码', required: true }]">
          <a-input-password v-model="form.password" size="large" placeholder="密码" @pressEnter="handleLogin">
            <a-icon slot="prefix" type="lock" style="color: rgba(0,0,0,.25);"></a-icon>
          </a-input-password>
        </a-form-model-item>
      </template>
      <a-form-model-item>
        <a-button type="primary" :block="true" :loading="loading" size="large" @click="handleLogin">登录</a-button>
      </a-form-model-item>
    </a-form-model>
    <div>
      <a @click="handleChange" style="user-select: none;">{{ loginType === 'phone' ? '密码' : '手机' }}登录</a>
      <router-link :to="{ name: 'Register' }" style="float: right; user-select: none;">免费注册</router-link>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { sendLoginVerificationCode } from '@/api/auth'
import { RSAEncrypt } from '@/utils/encrypt'

export default {
  name: 'Login',
  data () {
    return {
      form: {},
      rules: {
        phone_number: [
          { message: '请输入手机号码', required: true },
          { message: '请输入正确的手机号码', pattern: /^1[3456789]\d{9}$/, trigger: 'blur' }
        ]
      },
      loginType: 'password',
      disabled: false,
      time: 60,
      loading: false
    }
  },
  methods: {
    ...mapActions(['Login']),
    handleSend () {
      this.$refs.form.validateField('phone_number', error => {
        if (!error) {
          this.disabled = true
          sendLoginVerificationCode({ phone_number: this.form.phone_number }).then(res => {
            this.$message.success(res.message)
            const interval = window.setInterval(() => {
              if (this.time-- <= 0) {
                this.time = 60
                this.disabled = false
                window.clearInterval(interval)
              }
            }, 1000)
          })
        }
      })
    },
    handleLogin () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          this.Login({
            login_type: this.loginType,
            phone_number: this.form.phone_number,
            encrypted_data: RSAEncrypt(this.loginType === 'phone' ? this.form.verification_code : this.form.password)
          }).then(() => {
            this.$notification.success({ message: '登陆成功', description: '欢迎使用立维站点风险检测系统' })
            this.$router.push({ path: '/admin' })
            this.$refs.form.resetFields()
          }).finally(() => { this.loading = false })
        }
      })
    },
    handleChange () {
      if (this.loginType === 'phone') {
        this.loginType = 'password'
      } else {
        this.loginType = 'phone'
      }
    }
  }
}
</script>
